/**
 * Page for the conclusion of the pre-screener.
 */

import React from 'react';
import { Link, navigate, withPrefix, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/PageLayout';
import Safe from '../components/Safe';
import { localizer } from '../util/localizer';
import { css } from '../util/pagetools';

export default function ThankYou(props) {
  const ctx = props.pageContext;
  const localized = localizer(ctx);
  const backHome = localized('/');
  const cfg = (props.location ? (props.location.state || {}) : {});
  const isBrowser = typeof window !== 'undefined';
  // Redirect to home page if page is accessed without proper parameters
  if (Object.keys(cfg).length===0) {
    if (isBrowser) navigate(backHome);
    return null;
  }
  const localHomepage = props.data.homePage;

  const studydata = cfg.sitedata;
  // For the ThankYou page, use "site" data instead of study-specific data for links and other selected fields
  studydata.links = localHomepage.links;
  studydata.footer = localHomepage.footer;
  studydata.sitename = localHomepage.sitename;

  return (
    <Layout config={studydata} ctx={ctx} currentpath={props.pagepath}>
      <Helmet htmlAttributes={{lang: ctx.locale}}>
        <meta charSet="utf-8" />
        <title>Thank You | {studydata.title}</title>
        <link rel="canonical" href={`${studydata.siteurl}/thank-you/`} />
        <meta name="description" content={cfg.header}></meta>
        <script src={withPrefix('optanonwrapper.js')} />
      </Helmet>
      {renderBody(cfg.header, cfg.text, (cfg.theme || 'pary-on-light'), backHome, studydata.homelink)}
    </Layout>
  );
};

function renderBody(header, paragraphs, theme, linkto, linklabel) {
  return (
    <div className={css('container-fluid pt-50', theme)}>
      <div>
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
            <h1 className="pary-on-light flu-header">{header}</h1>
            <article className="node node-page view-mode-full clearfix" about="/finished" typeof="foaf:Document">
              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                <div className="field-items">
                  <div className="field-item even" property="content:encoded">
                    {paragraphs.map((item, index) => <p key={`p-${index}`} className="text-gray">{item}</p>)}
                    <div className="thank-you-btn">
                      <Safe to={linkto} className="slide-btn btn-theme-2" type="link" content={linklabel} />
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query thankYouQ($locale: String) {
    homePage: siteYaml(locale: {eq: $locale}) {
      siteurl
      homelink
      sitename
      links {
        id
        title
        linkto
      }
      footer {
        links {
          label
          to
        }
        text
      }
    }
  }`;
