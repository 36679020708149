/**
 * PageLayout is a default page wrapper defining a study page.
 * Supported props: config, ctx, currentpath
 */

import React from 'react';
import Nav from './Nav';
import Footer from './Footer';
import { makeTopLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';

export default function PageLayout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = makeTopLinks(cfg.links);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };

  return (
    <div id="page">
      <header>
        <Nav toplinks={toplinks}
             sitename={cfg.sitename}
             nonTranslated={cfg.nonTranslated}
             ctx={props.ctx}
             currentpath={props.currentpath}
             localizerfn={localized} />
      </header>
      {props.children}
      <Footer src={footdata} localizerfn={localized} />
    </div>
  );
};
